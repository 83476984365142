<template>
  <div>
    <iframe
        :src="game.url"
        style="border: none;"
        :height="game.height"
        :width="game.width"
    >
    </iframe>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import router from "../router/index";

export default {
  components: {
  },
  data() {
    return {
      game: {
        url: null,
        height: null,
        width: null,
      }
    }
  },
  beforeMount() {
    const splitedUrl = this.$route.path.split("/");
    const game = splitedUrl[splitedUrl.length-1];

    this.game.height = window.innerHeight;
    this.game.width = window.innerWidth;
    this.game.url = `https://dg-web-resources.s3-sa-east-1.amazonaws.com/games/${game}/index.html`;
  },
  methods: {

  }
};
</script>
